import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectEvents, fetchEvents, fetchGunTimes } from '../events/eventsSlice';
import { fetchRaces, selectRaces, setSubtractGun, setGunTime } from '../races/racesSlice';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { secondsToHms } from "../../utils/timeFormat";
import supabase from "../../utils/supabase";
import { useNavigate } from 'react-router-dom';

export function AdminPage() {
    const [selectedEvent, setSelectedEvent] = useState('');
    const dispatch = useAppDispatch();
    const events = useAppSelector(selectEvents);
    const races = useAppSelector(selectRaces);
    const fetchInterval = 20; // Seconds
    const navigate = useNavigate();
    const [signedIn, setSignedIn] = useState(false);

    const checkSignedIn = async () => {
        const { data, error } = await supabase.auth.getSession();
        if (data.session) {
            setSignedIn(true);
            console.log('signed in');
        } else {
            setSignedIn(false);
            navigate('/signin');
        }
        if (error) {
            console.error(error);
            setSignedIn(false);
            navigate('/signin');
        }
    }

    useEffect(() => {
        checkSignedIn();
        supabase.auth.onAuthStateChange((event, session) => {
            // console.log(event, session);
            if (event === 'SIGNED_OUT') {
                setSignedIn(false);
                navigate('/signin');
            }
        })

    }, []);

    const handleSignOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (events.events.length === 0) {
            dispatch(fetchEvents());
        }
        if (selectedEvent) {
            dispatch(fetchRaces(selectedEvent));
            dispatch(fetchGunTimes(selectedEvent));
        }
        const fetchTimesInterval = setInterval(() => {
            if (selectedEvent) {
                dispatch(fetchRaces(selectedEvent));
                dispatch(fetchGunTimes(selectedEvent));
            }
        }, (fetchInterval * 1000));
        return () => {
            clearInterval(fetchTimesInterval);
        }
    }, [dispatch, selectedEvent]);

    if (events.status === 'loading') {
        return <p>Loading events</p>
    }
    // if (events.gun_times_status === 'loading') {
    //     return <p>Loading gun times</p>
    // }
    // if (races.status === 'loading') {
    //     return <p>Loading races</p>
    // }

    if (signedIn) {
        return (
            <>
                <div style={{ height: '14px' }}></div>
                <div style={{ display: 'flex' }}>
                    <h1 style={{ marginBottom: '10px'}}>Admin</h1>
                    <button className='button2' onClick={handleSignOut} style={{ marginLeft: 'auto'}}>Sign Out</button>
                </div>
    
                {/* Select Event: */}
                <FormControl sx={{ m: 0, width: 300 }} size='small'>
                    <InputLabel id="event-select" style={{backgroundColor: 'var(--bg-primary)'}}>Event</InputLabel>
                    <Select
                        labelId="event-select"
                        id="event-select"
                        value={selectedEvent}
                        onChange={(event) => {
                            setSelectedEvent(event.target.value);
                        }}
                        style={{ width: '400px', marginBottom: '14px' }}
                    >
                        {events.events.map((event) => (
                            <MenuItem
                                key={event.id}
                                value={event.id}
                            >
                                {event.displayName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {races.races.length > 0 ? 
                    <>
                        <div style={{ marginBottom: '6px'}}>
                            <span>Gun Times: </span>
                            {events.gun_times.map((gunTime, i) => (
                                <span key={i}>{secondsToHms(gunTime.time) + ', '}</span>
                            ))}
                        </div>
                        <h3 style={{ marginBottom: '6px' }}>Races</h3>
                        {races.races.map((race) => (
                            <div key={race.id} style={{ backgroundColor: 'var(--bg-secondary)', margin: '0 0 4px 0', padding: '6px', borderRadius: '10px'}}>
                                <div style={{ fontSize: '16px', fontWeight: 600, margin: '0 0 12px 0'}}>{race.displayName}</div>
                                <div style={{ display: 'flex'}}>
                                    <FormControl sx={{ m: 0, width: 150 }} size='small'>
                                        <InputLabel id="gun-time-select" style={{backgroundColor: 'var(--bg-secondary)'}}>CSV Time</InputLabel>
                                        <Select
                                            labelId="csv-time-select"
                                            id="csv-time-select"
                                            value={race.subtractGun}
                                            onChange={async (event) => {
                                                const newValue = event.target.value === 'true' ? true : false;
                                                dispatch(setSubtractGun({raceId: race.id, newState: event.target.value}));
                                                // updateDoc(doc(db, 'races', race.id), {
                                                //     subtractGun: event.target.value,
                                                // })
                                                const { data, error } = await supabase
                                                .from('races')
                                                .update({ subtract_gun: newValue })
                                                .eq('id', race.id)
                                                .select()
                                                if (error) {
                                                    alert(error);
                                                }
                                            }}
                                        >
                                            <MenuItem key={0} value={'false'}>Actual</MenuItem>
                                            <MenuItem key={1} value={'true'}>Subtract Gun</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {race.subtractGun === 'true' ? 
                                        <FormControl sx={{ m: 0, width: 150 }} size='small' style={{ marginLeft: '6px'}}>
                                            <InputLabel id="gun-time-select" style={{backgroundColor: 'var(--bg-secondary)'}}>Gun Time</InputLabel>
                                            <Select
                                                labelId="gun-time-select"
                                                id="gun-time-select"
                                                value={race.gunTime}
                                                onChange={async (event) => {
                                                    dispatch(setGunTime({raceId: race.id, newGunTime: event.target.value}));
                                                    // updateDoc(doc(db, 'races', race.id), {
                                                    //     gunTime: Number(event.target.value),
                                                    // })
                                                    const { data, error } = await supabase
                                                    .from('races')
                                                    .update({ gun_time: event.target.value })
                                                    .eq('id', race.id)
                                                    .select()
                                                    if (error) {
                                                        alert(error);
                                                    }
                                                }}
                                            >
                                                <MenuItem
                                                    key={100000000}
                                                    value={0}
                                                >
                                                    None
                                                </MenuItem>
                                                {events.gun_times.map((gunTime, i) => (
                                                    <MenuItem
                                                        key={i}
                                                        value={gunTime.time}
                                                    >
                                                        {secondsToHms(gunTime.time)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    : null}
                                </div>
                            </div>
                        ))}
                    </>
                : <p>No races found</p>}
            </>
        )
    } else {
        // not signed in
        return null;
    }
}