import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectEvents } from './eventsSlice';
import { fetchEvents } from './eventsSlice';
import { Event } from '../event/Event';
import ReactGA from 'react-ga4';

export function Events() {
    const dispatch = useAppDispatch();
    const events = useAppSelector(selectEvents);

    // Google Analytics:
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/", title: "Events" });
    }, [])

    useEffect(() => {
        dispatch(fetchEvents());
    }, [dispatch])

    if (events.status === 'loading') {
        return <p>Loading events</p>
    }
    
    if (events.status === 'failed') {
        return <p>Error loading events </p>
    }
    return (
        <div style={{paddingTop: '4px'}}>
            <h2 style={{ marginBottom: '10px', marginTop: '10px', color: 'var(--accent-primary)' }}>Events</h2>
            {events.events.map((event, i) => {
                return (
                    <Event 
                        key={i} 
                        eventId={event.id}
                        displayName={event.displayName}
                        time={event.time}
                    />
                )
            })}
        </div>
    )
}
