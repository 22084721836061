import eventStyles from './Event.module.css';
import { Link } from "react-router-dom";
import { ReactComponent as CalendarIcon } from '../../icons/calendar3-event.svg';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import { dateToStringDayTime } from '../../utils/timeFormat';

export function Event({ eventId = '', displayName = 'Event name', time = 2387462387 }: {eventId?: string, displayName?: string, time?: number}) {

    return (
        <Link to={`/${eventId}`}>
            <div className={eventStyles.container} >
                <div className={eventStyles.name}>
                    {displayName}
                </div>
                <div className={eventStyles.time}>
                    <CalendarIcon style={{ fill: 'var(--text-primary)', height: '14px', width: 'auto', margin: '0 3px -1px 0' }}/>
                    {dateToStringDayTime(time).day}
                    <ClockIcon style={{ fill: 'var(--text-primary)', height: '14px', width: 'auto', margin: '0 3px -1px 6px' }}/>
                    {dateToStringDayTime(time).time}
                </div>
            </div>
        </Link>
    )
}