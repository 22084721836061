import logo from '../../icons/pes-logo-white-no-com.png';

export function Footer() {
  return (
    <div style={{ 
            paddingTop: '3px', 
            paddingBottom: '3px', 
            // borderTop: '1px solid var(--bg-secondary)', 
            boxShadow: '0 -2px 3px 0 rgba(0,0,0,.2)', 
            zIndex: '100', 
            position: 'fixed', 
            bottom: '0', 
            width: '100%',
            backgroundColor: 'var(--text-secondary)',
        }}>
        <div className='app-container'>
            <a href='https://www.premiereventsupport.com/' target='_blank' style={{ display: 'flex'}}>
                <img src={logo} width='auto' height='43px' alt='Premier Event Support logo' />
                <span style={{ color: 'var(--pes-blue)', margin: '0 0 0 6px', fontSize: '14px', fontWeight: '600'}}>Race Timing</span>
            </a>
        </div>
    </div>
  )
}
