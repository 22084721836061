import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import supabase from '../../utils/supabase';

export interface RacesState {
  status: string;
  races: any;
}

const initialState: RacesState = {
  status: 'idle',
  races: []
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchRaces = createAsyncThunk(
  'races/fetchRaces',
  async (eventId: string, { rejectWithValue }) => {
    console.log(`fetching races for ${eventId}`);

    let newRaces = [];

    let { data: races, error } = await supabase
    .from('races')
    .select('*')
    .eq('event_id', eventId)
    .order('display_name')

    if (races) {
      races.forEach((race) => {
        newRaces.push({
          id: race.id,
          csvName: race.csv_name,
          displayName: race.display_name,
          time: Date.parse(race.time) / 1000,
          official: race.official,
          subtractGun: race.subtract_gun ? 'true' : 'false', // Need to switch to string because that's what MUI select requires
          gunTime: race.gun_time === null ? 0 : race.gun_time,
          comment: race.comment === '' ? 0 : race.comment,
        });
      });

      return newRaces;

    } else {
      rejectWithValue('Error fetching events. No events.');
    }
    if (error) {
      console.error(error);
      rejectWithValue('Error fetching events.');
    }
  }
);

export const racesSlice = createSlice({
  name: 'races',
  initialState,
  reducers: {
    setSubtractGun: (state, action) => {
      const { raceId, newState } = action.payload;
      const index = state.races.findIndex(race => race.id === raceId);
      if (index !== -1) {
        state.races[index].subtractGun = newState;
      }
    },
    setGunTime: (state, action) => {
      const { raceId, newGunTime } = action.payload;
      const index = state.races.findIndex(race => race.id === raceId);
      if (index !== -1) {
        state.races[index].gunTime = newGunTime;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRaces.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRaces.fulfilled, (state, action) => {
        state.status = 'idle';
        state.races = action.payload;
      })
      .addCase(fetchRaces.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setSubtractGun, setGunTime } = racesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRaces = (state: RootState) => state.races;

export default racesSlice.reducer;
