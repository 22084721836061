import React from 'react';
import timeStyles from './Time.module.css';
import { secondsToHms } from '../../utils/timeFormat';
import { ReactComponent as StopwatchIcon } from '../../icons/stopwatch.svg';
import { Tooltip } from '@mui/material';
import { allowedTimeCodes } from '../../utils/timeFormat';

export function Time({ position, first, last, number, age, time }: {position: number, first: string, last: string, number: string, age: number, time: number}) {
  const timeStr = secondsToHms(time);
  let tooltip = '';
  const foundCode = Object.keys(allowedTimeCodes).includes(timeStr);
  if (foundCode) {
    tooltip = allowedTimeCodes[timeStr].exp;
  }

  return (
    <div className={timeStyles.container}>
      <div className={timeStyles.position}>{foundCode ? '' : position}</div>
      <div className={timeStyles.numName}>
        <span style={{ fontWeight: '500' }}>{`${first} ${last}`}</span>
        <span>{` · ${number}`}</span>
      </div>
      <div className={timeStyles.age}>{age ? `${age}y` : null}</div>
      <div className={timeStyles.time}>
        <StopwatchIcon className={foundCode ? 'display-none' : ''} fill='var(--text-secondary)' style={{ height: '14px', width: 'auto', marginRight: '3px', marginBottom: '-1px' }} />
        <Tooltip title={tooltip} placement='left'>
          <span>{timeStr}</span>
        </Tooltip>
      </div>
    </div>
  )
}
