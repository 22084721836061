import raceStyles from './Race.module.css';
import { Link } from "react-router-dom";
import { ReactComponent as CalendarIcon } from '../../icons/calendar3-event.svg';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import { useParams } from 'react-router-dom';
import { dateToStringDayTime } from '../../utils/timeFormat';

export function Race({ id = 'id', displayName = 'Race name', time = 2387462387, csvName = 'csv-name-undefined' }: {id?: string, displayName?: string, time?: number, csvName?: string}) {
    let { eventId } = useParams();

    return (
        <Link to={`/${eventId}/${id}`}>
            <div className={raceStyles.container} >
                <div className={raceStyles.name}>
                    {displayName}
                </div>
                <div className={raceStyles.time}>
                    <CalendarIcon style={{ fill: 'var(--text-primary)', height: '14px', width: 'auto', margin: '0 3px -1px 0' }}/>
                    {dateToStringDayTime(time).day}
                    <ClockIcon style={{ fill: 'var(--text-primary)', height: '14px', width: 'auto', margin: '0 3px -1px 6px' }}/>
                    {dateToStringDayTime(time).time}
                </div>
            </div>
        </Link>
    )
}
