import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import eventsReducer from '../features/events/eventsSlice';
import racesReducer from '../features/races/racesSlice';
import timesReducer from '../features/times/timesSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    events: eventsReducer,
    races: racesReducer,
    times: timesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
