import { ReactComponent as ChevronLeftIcon } from '../../icons/chevron-left.svg';
import { Link } from "react-router-dom";

export function BackButton({ link = '/', text = ''}: { link: string, text: string}) {
  return (
    <Link to={link}>
        <div style={{ display: 'flex', verticalAlign: 'center', marginBottom: '6px', marginLeft: '-5px', paddingTop: '10px' }}>
            <ChevronLeftIcon fill='var(--text-primary)' height='18px' width='18px' />
            <span style={{ color: 'var(--text-primary)', fontSize: '16px', lineHeight: '16px', marginTop: '1px'}}>{text}</span>
        </div>
    </Link>
  )
}
