import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import supabase from '../../utils/supabase';
import { useNavigate } from 'react-router-dom';

export function SignInPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const navigate = useNavigate();

    const handleSignIn = async () => {
        const { data, error } = await supabase.auth.signInWithPassword({
            email,
            password,
        })
        if (data.session) {
            navigate('/admin');
        }
        if (error) {
            console.error(error);
            setErrorMsg(error.message);
        }
    }

    return (
        <div style={{ maxWidth: '330px', padding: '30px 0', margin: '20px auto' }}>
            <h1>Sign In</h1>
            <FormControl sx={{ m: 0, width: '100%' }} size='small'>
                <TextField 
                    id="email" 
                    label="Email" 
                    variant="outlined"
                    type="text"
                    value={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEmail(event.target.value);
                    }}
                    style={{ marginTop: '20px'}}
                />
                <TextField 
                    id="password" 
                    label="Password" 
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPassword(event.target.value);
                    }}
                    style={{ marginTop: '20px'}}
                />
                <button 
                    className='button1' 
                    style={{ marginTop: '20px' }}
                    onClick={handleSignIn}
                >Sign In</button>
            </FormControl>
            <p style={{color: 'var(--error)', marginTop: '10px' }}>{errorMsg}</p>
        </div>
    )
}
