import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectRaces, fetchRaces } from './racesSlice';
import { selectEvents, fetchEvents } from '../events/eventsSlice';
import { Race } from '../race/Race';
import { useParams, useNavigate } from 'react-router-dom';
import { BackButton } from '../backButton/BackButton';
import { EventBanner } from '../eventBanner/EventBanner';
import ReactGA from 'react-ga4';

export function Races() {
    const dispatch = useAppDispatch();
    const events = useAppSelector(selectEvents);
    const races = useAppSelector(selectRaces);
    let { eventId } = useParams();
    const navigate = useNavigate();

    const currentEvent = events.events.find(event => event.id === eventId);

    // Google Analytics:
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/${eventId}`, title: `Event: ${currentEvent ? currentEvent.displayName : `not found: ${eventId}`}` });
    }, [eventId]);

    useEffect(() => {
        dispatch(fetchRaces(eventId));
        if (events.events.length === 0) {
            dispatch(fetchEvents());
        }
    }, [dispatch, eventId])

    if (currentEvent === undefined) {
        return (
            <div style={{ paddingTop: '20px'}}>
                <p>{`Sorry, this event could not be found.`}</p>
                <br></br>
                <button className='button1' onClick={() => navigate('/')}>Back to Events</button>
            </div>
        )
    }

    if (races.status === 'loading' || events.status === 'loading') {
        return <p>Loading races</p>
    }
    
    if (races.status === 'failed') {
        return <p>Error loading races </p>
    }

    return (
        <>
            <BackButton link='/' text='Events' />
            <EventBanner bannerUrl={currentEvent.bannerUrl} bannerUrlMobile={currentEvent.bannerUrlMobile}/>
            <h2 style={{ marginBottom: '10px', marginTop: '10px', color: 'var(--accent-primary)' }}>{currentEvent ? currentEvent.displayName : 'Races'}</h2>
            <div style={{marginBottom: '20px'}}>
                {races.races.map((race, i) => {
                    return (
                        <Race 
                            key={i}
                            id={race.id}
                            displayName={race.displayName}
                            time={race.time} 
                            csvName={race.csvName}
                        />
                    )
                })}
            </div>
        </>
    )
}
