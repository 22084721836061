import './App.css';
import './reset.css';
import { Events } from './features/events/Events';
import { Races } from './features/races/Races';
import { Times } from './features/times/Times';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Header } from './features/header/Header';
import { Footer } from './features/footer/Footer';
import { AdminPage } from './features/adminPage/AdminPage';
import { SignInPage } from './features/signInPage/SignInPage';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-RHBQH8M3N4');

function App() {
  return (
    <>
      <Router>
        <Header />
        <div className='app-container' style={{ marginTop: '48px', marginBottom: '58px'}}>
            <Routes>
              <Route path='/' element={<Events />} />
              <Route path='/:eventId' element={<Races />} />
              <Route path='/:eventId/:raceId' element={<Times />} />
              <Route path='/admin' element={<AdminPage />} />
              <Route path='/signin' element={<SignInPage />} />
            </Routes>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
