import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import supabase from '../../utils/supabase';

export interface EventsState {
  status: string;
  events: any;
  gun_times_status: string;
  gun_times: any;
}

const initialState: EventsState = {
  status: 'idle',
  events: [],
  gun_times_status: 'idle',
  gun_times: []
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchEvents = createAsyncThunk(
  'events/fetchEvents',
  async (_, {rejectWithValue}) => {
    console.log(`fetching events`);

    let newEvents = [];

    let { data: events, error } = await supabase
    .from('events')
    .select('*')
    .order('time', { ascending: false })

    if (events) {
      events.forEach((event) => {
        newEvents.push({
          id: event.id,
          displayName: event.display_name,
          time: Date.parse(event.time) / 1000,
          bannerUrl: event.banner_url,
          bannerUrlMobile: event.banner_url_mobile,
        });
      })
      return newEvents;
    } else {
      rejectWithValue('Error fetching events. No events.');
    }
    if (error) {
      console.error(error);
      rejectWithValue('Error fetching events.');
    }
  }
);

export const fetchGunTimes = createAsyncThunk(
  'events/fetchGunTimes',
  async (eventId: string, {rejectWithValue}) => {
    console.log(`fetching gun times for ${eventId}`);
    let { data: gun_times, error } = await supabase
    .from('gun_times')
    .select('*')
    .eq('event_id', eventId)

    if (gun_times) {
      return gun_times;
    } else {
      rejectWithValue('Error fetching gun_times. No gun_times.');
    }
    if (error) {
      console.error(error);
      rejectWithValue('Error fetching gun_times.');
    }
  }
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.status = 'idle';
        state.events = action.payload;
      })
      .addCase(fetchEvents.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchGunTimes.pending, (state) => {
        state.gun_times_status = 'loading';
      })
      .addCase(fetchGunTimes.fulfilled, (state, action) => {
        state.gun_times_status = 'idle';
        state.gun_times = action.payload;
      })
      .addCase(fetchGunTimes.rejected, (state) => {
        state.gun_times_status = 'failed';
      });
  },
});

// export const {  } = eventsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectEvents = (state: RootState) => state.events;

export default eventsSlice.reducer;
