import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { fetchEvents, selectEvents } from '../events/eventsSlice';
import { selectRaces, fetchRaces } from '../races/racesSlice';
import { selectTimes, fetchTimes } from './timesSlice';
import { Time } from '../time/Time';
import { ReactComponent as CalendarIcon } from '../../icons/calendar3-event.svg';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import { ReactComponent as InfoIcon } from '../../icons/info-circle.svg';
import { ReactComponent as CircleIcon } from '../../icons/circle-fill.svg';
import { ReactComponent as CheckCircleIcon } from '../../icons/check-circle-fill.svg';
import { BackButton } from '../backButton/BackButton';
import { dateToStringDayTime } from '../../utils/timeFormat';
import { EventBanner } from '../eventBanner/EventBanner';
import ReactGA from 'react-ga4';
import { allowedTimeCodes } from '../../utils/timeFormat';

export function Times() {
    const fetchInterval = 60; // Seconds
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    let { eventId, raceId } = useParams();
    const events = useAppSelector(selectEvents);
    const navigate = useNavigate();
    const races = useAppSelector(selectRaces);
    const times = useAppSelector(selectTimes);
    const currentEvent = events.events.find(event => event.id === eventId);
    const raceObj = races.races.find(item => item.id === raceId);

    // Google Analytics:
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/${eventId}/${raceId}`, title: `Event: ${currentEvent ? currentEvent.displayName : `not found: ${eventId}`}, Race: ${raceObj ? raceObj.displayName : `not found: ${raceId}`}` });
    }, [eventId, raceId]);

    const onSearchChange = (event) => {
        setSearch(event.target.value);
      };

    useEffect(() => {
        if (events.events.length === 0) {
            dispatch(fetchEvents());
        }
        dispatch(fetchRaces(eventId));
        dispatch(fetchTimes(raceId));
        const fetchTimesInterval = setInterval(() => {
            dispatch(fetchTimes(raceId));
            dispatch(fetchRaces(eventId));
        }, (fetchInterval * 1000));
        return () => {
            clearInterval(fetchTimesInterval);
        }
    }, [dispatch, eventId]);

    // if (races.races.length < 1) {
    //     return <p>No races to display</p>
    // }
    if (events.status === 'loading') {
        return <p>Loading</p>
    }
    if (times.status === 'loading' && !times.records) {
        return <p>Loading times</p>
    }
    if (races.status === 'failed') {
        return <p>Error loading races </p>
    }
    if (times.status === 'failed') {
        return <p>Error loading times </p>
    }
    if (events.status === 'failed') {
        return <p>Error loading events </p>
    }

    if (raceObj === undefined) {
        return (
            <div style={{ paddingTop: '20px'}}>
                <p>{`Sorry, this race could not be found.`}</p>
                <br></br>
                <button className='button1' onClick={() => navigate('/')}>Back to Events</button>
            </div>
        )
    }

    let timesFilteredF = times.records.filter(time => time.sex === 'F' && (time.number + ' ' + time.first + ' ' + time.last).toLowerCase().includes(search.toLowerCase()));
    let timesFilteredM = times.records.filter(time => time.sex === 'M' && (time.number + ' ' + time.first + ' ' + time.last).toLowerCase().includes(search.toLowerCase()));
    timesFilteredF.sort(function(a, b){return a.position - b.position});
    timesFilteredM.sort(function(a, b){return a.position - b.position});


    const getDisplayTime = (time) => {
        if (raceObj.subtractGun === 'false') {
            return time;
        }
        if (Object.keys(allowedTimeCodes).includes(time)) {
            return time;
        }
        return time - raceObj.gunTime;
    }
    
    return (
        <>
            <div style={{ backgroundColor: 'var(--bg-primary)', marginBottom: '0px', width: '100%'}}>
                <BackButton link={`/${eventId}`} text='Races' />
                <EventBanner bannerUrl={currentEvent.bannerUrl} bannerUrlMobile={currentEvent.bannerUrlMobile}/>
                <h2 style={{ color: 'var(--accent-primary)', marginBottom: '4px', fontSize: '26px' }}>{raceObj.displayName}</h2>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <CalendarIcon style={{ fill: 'var(--text-secondary)', height: '14px', width: 'auto', margin: '0 3px 0px 0' }}/>
                    <p style={{ color: 'var(--text-secondary)', marginBottom: '4px', fontSize: '14px' }}>{dateToStringDayTime(raceObj.time).day}</p>
                    <ClockIcon style={{ fill: 'var(--text-secondary)', height: '14px', width: 'auto', margin: '0 3px 0px 6px' }}/>
                    <p style={{ color: 'var(--text-secondary)', marginBottom: '4px', fontSize: '14px' }}>{dateToStringDayTime(raceObj.time).time}</p>
                </div>
                {/* Official/Unofficial */}
                <div style={{ marginBottom: '4px', marginTop: '2px' }}>
                    {raceObj.official ? (
                        <h3><CheckCircleIcon fill='var(--success)'/> Official Results</h3>
                    ) : (
                        <h3><CircleIcon fill='var(--warning)'/> Unofficial Results</h3>
                    )}
                </div>
                <div>
                    <input value={search} onChange={onSearchChange} placeholder='search' style={{ fontSize: '16px', lineHeight: '20px', backgroundColor: 'var(--bg-primary', borderRadius: '3px', border: '1px solid grey', marginBottom: '10px', width: 'calc(100% - 10px)', paddingRight: '6px', maxWidth: '500px' }} />
                </div>
            </div>
            

            {raceObj.subtractGun === 'false' || (raceObj.subtractGun === 'true' && raceObj.gunTime > 0) ?
                // If subtracting gun time, only display results when gun time is set
                <div style={{ marginTop: '3px', marginBottom: '20px'}}>
                <div style={{ overflowY: 'scroll'}}>
                    {timesFilteredF.length === 0  && timesFilteredM.length === 0 && search.length === 0 ? <p style={{ marginTop: '16px' }}>No times to display yet!</p> : null}
                    {timesFilteredF.length === 0  && timesFilteredM.length === 0 && search.length > 0 ? <p style={{ marginTop: '16px' }}>No times found</p> : null}

                    {timesFilteredF.length > 0 ? <h4>Female</h4> : null}
                    {timesFilteredF.map((time, i) => {
                        return (
                            <Time 
                                key={i}
                                position={time.position}
                                first={time.first} 
                                last={time.last}
                                number={time.number}
                                age={time.age}
                                time={getDisplayTime(time.time)} 
                            />
                        )
                    })}
                    <div style={{ marginBottom: '12px' }}></div>
                    {timesFilteredM.length > 0 ? <h4>Male</h4> : null}
                    {timesFilteredM.map((time, i) => {
                        return (
                            <Time 
                                key={i} 
                                position={time.position}
                                first={time.first} 
                                last={time.last} 
                                number={time.number}
                                age={time.age}
                                time={getDisplayTime(time.time)} 
                            />
                        )
                    })}
                </div>
            </div>
            : <p>No times to display yet!</p>}
            {raceObj.comment ? 
            <div style={{backgroundColor: 'var(--info)', padding: '5px 6px', margin: '0 0 20px 0', borderRadius: '4px', display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '20px', height: '20px', margin: '0 6px 0 0' }}><InfoIcon width='20px' height='20px'/></div>
                <p>{raceObj.comment}</p>
            </div>
            : null}
        </>
    )
}
