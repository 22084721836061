export const allowedTimeCodes = { 
    'DNF': {
        code: 'DNF',
        exp: 'Did not finish'
    }, 
    'NS': {
        code: 'NS',
        exp: 'No show'
    }, 
    'DQ': {
        code: 'DQ',
        exp: 'Disqualified'
    }, 
    'NT': {
        code: 'NT',
        exp: 'No time'
    }
};

export const secondsToHms = (s) => {
    // v1 of app used number data type for time, then later switched to string to support allowed time codes.
    // Currently only supports up to 24 hours
    const allowedDataTypes = [ 'number', 'string'];
    if (!allowedDataTypes.includes(typeof s)) {
        console.error(`error converting time ${s} to seconds or allowedTimeCode. Not a number or a string. Data type provided: ${typeof s}`);
        return null;
    }
    if (Object.keys(allowedTimeCodes).includes(s)) {
        return s;
    }

    let sNum = Number(s);
    if (typeof sNum !== 'number') {
        console.error(`error converting time ${s}. Failed to convert to a number.`);
    }

    if (sNum < 0) {
        console.error(`error converting time ${s} to seconds. Cannot be less than 0.`);
        return null;
    }
    const secondsDecimal = (sNum % 1).toFixed(1);
    const date = new Date(null);
    date.setSeconds(sNum);
    const result = sNum > 3600 ? date.toISOString().slice(11, 19) : date.toISOString().slice(14, 19);
    return result + '.' + secondsDecimal.toString().slice(2);
}

export const dateToStringDayTime = (dateSec: number) => {
    const date = new Date(dateSec * 1000);
    const day = date.toISOString().slice(0, 10);

    let hours = date.getHours() ; // gives the value in 24 hours format
    const AmOrPm = hours >= 12 ? 'pm' : 'am';
    hours = (hours % 12) || 12;
    let minutes = date.getMinutes();
    const minutesStr = (minutes < 10)?("0"+minutes.toString()):minutes.toString();
    const finalTime = hours + ":" + minutesStr + " " + AmOrPm; 
    return { day, time: finalTime};
}