import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { allowedTimeCodes } from '../../utils/timeFormat';
import supabase from '../../utils/supabase';

export interface TimesState {
  status: string;
  records: any;
}

const initialState: TimesState = {
  status: 'idle',
  records: []
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchTimes = createAsyncThunk(
  'times/fetchTimes',
  async (raceId: string, { getState, rejectWithValue }) => {
    console.log(`fetching times for race ${raceId}`);

    function compareFn(a, b) {
      if (Object.keys(allowedTimeCodes).includes(a.time)) {
        // non-numeric time codes to the bottom
        return 1;
      }
      const aTime = Number(a.time);
      const bTime = Number(b.time);
      if (aTime > bTime) {
        return 1;
      }
      if (aTime < bTime) {
        return -1;
      }
      // a must be equal to b
      return 0;
    }

    let newTimes = [];

    let { data: times, error } = await supabase
    .from('times')
    .select('*')
    .eq('race_id', raceId)

    if (times) {
      times.forEach((time) => {
        newTimes.push({
          id: time.id,
          first: time.first,
          last: time.last,
          number: time.number,
          sex: time.sex,
          age: time.age,
          time: time.time,
        });
      });

      const genders = ['M', 'F'];
      genders.forEach(gender => {
        // Assign positions:
        let timesFiltered = newTimes.filter(time => time.sex === gender);
        timesFiltered.sort(compareFn);
        for (let j = 0; j < timesFiltered.length; j++) {
          const currentId = timesFiltered[j].id;
          const recordsObjIndex = newTimes.findIndex(record => record.id === currentId);
          newTimes[recordsObjIndex].position = j + 1;
        }
      })
      
      return newTimes;
      
    } else {
      rejectWithValue('Error fetching times. No times.');
    }
    if (error) {
      console.error(error);
      rejectWithValue('Error fetching times.');
    }
  }
);

export const timesSlice = createSlice({
  name: 'times',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTimes.fulfilled, (state, action) => {
        state.status = 'idle';
        state.records = action.payload;
      })
      .addCase(fetchTimes.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// export const {  } = timesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTimes = (state: RootState) => state.times;

export default timesSlice.reducer;
