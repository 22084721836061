import logo from '../../icons/finishtime-logo.png';
import { Link } from 'react-router-dom';

export function Header() {
  return (
    <div style={{ 
            paddingTop: '4px', 
            paddingBottom: '2px', 
            borderBottom: '1px solid var(--bg-secondary)', 
            boxShadow: '0 2px 3px 0 rgba(0,0,0,.2)', 
            zIndex: '100', 
            position: 'fixed', 
            top: '0', 
            width: '100%',
            backgroundColor: 'var(--bg-primary)',
        }}>
        <div className='app-container'>
            <div style={{ display: 'flex'}}>
              <Link to='/'>
                <img src={logo} width='auto' height='39px' alt='Finishtime logo' />
              </Link>
            </div>
        </div>
    </div>
  )
}
