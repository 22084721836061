import { useState, useEffect } from 'react';

export function EventBanner({ bannerUrl = '', bannerUrlMobile = ''}: {bannerUrl: string, bannerUrlMobile: string}) {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    
    useEffect(() => {
        const handleWindowResize = () => {
        setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
        window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    if (!bannerUrl || !bannerUrlMobile || bannerUrl.length < 10 || bannerUrlMobile.length < 10) {
        //if no urls provided, no banner
        return null;
    }

    if (windowSize[0] > 700) {
        // Desktop
        return (
            <div style={{ borderRadius: '10px', overflow: 'hidden', marginBottom: '10px'}}>
                <img src={bannerUrl} width='100%' height='auto' alt='event banner' />
            </div>
        )
    }

    // Mobile
    return (
        <div style={{ borderRadius: '10px', overflow: 'hidden', marginBottom: '3px'}}>
            <img src={bannerUrlMobile} width='100%' height='auto' alt='event banner' />
        </div>
    )
}
